import { Box, CircularProgress, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, Theme } from '@material-ui/core/styles';
import DOMPurify from 'dompurify';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import colorContext from '../../Context/colorContext';
import {
  loadPendingTermSISECI,
  signTermSISECI,
} from '../../services/temosSISECI';
import { loadPendingTermSSO, signTermSSO } from '../../services/temosSSO';
import getCookie from '../../utils/getCookies';

export interface ITermSSO {
  id: string;
  titulo: string;
  versao: string;
  texto: string;
}
export interface ITermsSISECI {
  id: string;
  titulo: string;
  versao: string;
  texto: string;
}
export default function TermoDialog(): JSX.Element {
  const { colors } = useContext(colorContext);
  const [isWorkspace, setIsWorkspace] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleIsWorkspace = () => {
      const route = location.pathname;
      const result = /workspace/i.exec(route);
      if (result?.length) {
        setIsWorkspace(true);
      }
    };

    if (location) {
      handleIsWorkspace();
    }
  }, [location]);

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      backgroundColor: isWorkspace ? '#E8EBEF' : colors.colorBackground,
    },
    titleBox: {
      textAlign: 'center',
      backgroundColor: isWorkspace
        ? theme.palette.primary.main
        : colors.accentColorBackground,
      padding: 20,
      color: isWorkspace ? '#fff' : colors.textAccentColor,
    },
    boxTextTermo: {
      border: `1px solid ${
        isWorkspace ? '#E1E6EF' : colors.borderShadowsColor
      }`,
      padding: 10,
      height: '50vh',
      overflow: 'auto',
      color: isWorkspace ? '#6B6D6E' : colors.textBody,
    },
    footerTermo: {
      display: 'flex',
      padding: 20,
      justifyContent: 'space-between',
      backgroundColor: isWorkspace ? '#E8EBEF' : colors.colorBackground,
    },
    btnRecusar: {
      width: '45%',
      padding: 10,
      color: isWorkspace ? theme.palette.primary.main : colors.accentColor,
      '&:hover': {
        backgroundColor: isWorkspace
          ? '#ffffff'
          : colors.colorBackgroundSecundary,
      },
    },
    textRecusar: {
      textDecoration: 'underline',
    },
    btnAceitar: {
      backgroundColor: isWorkspace ? '#3CD278' : colors.buttonSuccessColor,
      color: isWorkspace ? '#ffffff' : colors.colorBackgroundSecundary,
      width: '45%',
      padding: 10,
      '&:hover': {
        backgroundColor: isWorkspace
          ? theme.palette.success
          : colors.buttonSuccessColor,
      },
    },
    btnAceitando: {
      backgroundColor: isWorkspace ? '#3CD278' : colors.buttonSuccessColor,
      color: isWorkspace ? '#ffffff' : colors.colorBackgroundSecundary,
      width: '100%',
      padding: 10,
      '&:hover': {
        backgroundColor: isWorkspace
          ? theme.palette.success
          : colors.buttonSuccessColor,
      },
    },
    subTitle: {
      color: isWorkspace ? '#6B6D6E' : colors.textBody,
    },
  }));
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [penddingTermsSSO, setPenddingTermsSSO] = useState<ITermSSO[]>([]);
  const [penddingTermsSISECI, setPenddingTermsSISECI] = useState<
    ITermsSISECI[]
  >([]);
  const [loadingSignTerm, setLoadingSignTerm] = useState<boolean>(false);
  const [sisTermCurrent, setSisTermCurrent] = useState<string>('');
  // const containerRef = useRef<HTMLDivElement>(null);

  const token: string | null = getCookie('gov_access_token_sso');
  const tokenSiseci: string | null = getCookie('gov_access_token_sso');

  const handleClose = () => {
    setOpen(false);
  };

  const currentPenndingTermSISECI = async () => {
    try {
      const { data } = await loadPendingTermSISECI(token || '');
      setPenddingTermsSISECI(data);
      if (data.length) {
        setSisTermCurrent('SERVIÇOS');
        setOpen(true);
      }
    } catch (error) {
      setPenddingTermsSISECI([]);
      console.log('erro ao carregar termo siseci', error);
    }
  };

  const currentPenndingTermSSO = async () => {
    try {
      const { data } = await loadPendingTermSSO(token || '');
      setPenddingTermsSSO(data);
      if (data.length) {
        setSisTermCurrent('SSO');
        setOpen(true);
      } else if (tokenSiseci) {
        currentPenndingTermSISECI();
      }
    } catch (error) {
      setPenddingTermsSSO([]);
      console.log('erro ao carregar termo sso', error);
      currentPenndingTermSISECI();
    }
  };

  const signAllTermSSO = () => {
    setLoadingSignTerm(true);
    try {
      penddingTermsSSO.forEach(({ id }) => {
        signTermSSO(token || '', id);
      });
      setOpen(false);
      setLoadingSignTerm(false);
      currentPenndingTermSISECI();
    } catch (error) {
      console.log(error);
      setLoadingSignTerm(false);
      setOpen(false);
    }
  };

  const signAllTermSISECI = () => {
    setLoadingSignTerm(true);
    try {
      penddingTermsSISECI.forEach(({ id }) => {
        signTermSISECI(token || '', id);
      });
      setOpen(false);
      setLoadingSignTerm(false);
    } catch (error) {
      console.log(error);
      setLoadingSignTerm(false);
      setOpen(false);
    }
  };

  const handleSignTermsSSO = () => {
    signAllTermSSO();
  };
  const handleSignTermsSISECI = () => {
    signAllTermSISECI();
  };

  useEffect(() => {
    if (token) {
      currentPenndingTermSSO();
    }
    // if (tokenSiseci) {
    //   currentPenndingTermSISECI();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const renderTerms = (terms) => {
    if (!terms.length) return null;

    return terms.map(({ id, titulo, versao, texto }) => (
      <Box key={id}>
        <Typography variant='h4' className={classes.subTitle}>
          {titulo} - Versão ({versao})
        </Typography>
        <Box dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(texto) }} />
      </Box>
    ));
  };

  const termsToDisplay = sisTermCurrent === 'SSO' ? penddingTermsSSO : sisTermCurrent === 'SERVIÇOS' ? penddingTermsSISECI : [];

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby='alert-dialog-title' maxWidth='md'>
        <DialogTitle id='alert-dialog-title' className={classes.titleBox}>
          <Typography>{`TERMOS DE ACEITE ${sisTermCurrent}`}</Typography>
        </DialogTitle>
        <DialogContent classes={{ root: classes.root }}>
          <div className={classes.boxTextTermo}>
            {renderTerms(termsToDisplay) || (
              <Typography variant='body1'>Nenhum termo pendente disponível.</Typography>
            )}
          </div>
        </DialogContent>
        <Box className={classes.footerTermo}>
          {!loadingSignTerm ? (
            <>
              <Button onClick={handleClose} color='primary' className={classes.btnRecusar}>
                <Typography className={classes.textRecusar}>Recusar</Typography>
              </Button>

              <Button
                onClick={sisTermCurrent === 'SSO' ? handleSignTermsSSO : handleSignTermsSISECI}
                style={{
                  color: isWorkspace ? 'inherit' : '#333',
                  backgroundColor: isWorkspace ? '#3CD278' : colors.buttonSuccessColor,
                  width: '45%',
                  padding: 10,
                }}
              >
                <Typography>Aceitar</Typography>
              </Button>
            </>
          ) : (
            <Button color='primary' className={classes.btnAceitando} disabled>
              <Typography>
                Aceitando {'  '}
                <CircularProgress size={10} />
              </Typography>
            </Button>
          )}
        </Box>
      </Dialog>
    </div>
  );
}
