import { Box, List, ListItem, Typography } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useContext } from 'react';
import { Link as LinkScroll } from 'react-scroll';
import colorContext from '../../../../Context/colorContext';
import { SearchI } from '../../../SearchService';
// import CategoriesMenu from '../CategoriesMenu';
import DOMPurify from 'dompurify';

interface Props {
  service: SearchI;
}

export default function ServiceDetails({ service }: Props): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    main: {
      width: '100%',
      backgroundColor: colors.colorBackground,
      borderBottom: `1px solid ${colors.dividerColor}`,
      paddingBottom: 64,
    },
    title: {
      fontSize: theme.typography.pxToRem(24),
      color: colors.textBlackGray,
      fontFamily: 'Open Sans',
      fontWeight: 700,
      marginBottom: 16,
    },
    subTitle: {
      fontSize: theme.typography.pxToRem(16),
      color: colors.textBlackGray,
      fontFamily: 'Open Sans',
      fontWeight: 700,
      marginBottom: 16,
    },
    text: {
      fontSize: theme.typography.pxToRem(16),
      color: colors.textBlackGray,
      fontWeight: 400,
      fontStyle: 'normal',
      fontFamily: 'Open Sans',
    },
    accordion: {
      backgroundColor: colors.colorBackground,
      width: '100%',
      boxShadow: colors.boxShadowColor,
      '&::before': {
        display: 'none',
      },
      borderRadius: 4,
    },
    accordionRoot: {
      minHeight: 62,
    },
    accordionDetail: {
      padding: '0px 42px 16px 16px',
      wordBreak: 'break-word',
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
      marginLeft: 14,
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      color: colors.textAccentColor,
      borderRadius: 0,
      textTransform: 'capitalize',
    },
    buttonBack: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      textTransform: 'capitalize',
      color: '#000',
    },
    colorIcon: {
      color: colors.accentColor,
    },
    colorStepper: {
      backgroundColor: colors.colorBackground,
      '& .MuiStepLabel-active': {
        color: `${colors.accentColorSecondary} !important`,
      },
      '& .MuiStepLabel-completed': {
        color: `${colors.accentColorSecondary} !important`,
      },
      '& .MuiStepLabel-label': {
        color: colors.stepLabelColor,
      },
      '& .MuiStepIcon-active': {
        color: colors.iconStepColor,
      },
      '& .MuiStepIcon-completed': {
        color: colors.accentColorSecondary,
      },
    },
    boxNav: {
      width: '100%',
      backgroundColor: colors.colorBackgroundNavSticky,
      borderTop: `1px solid ${colors.dividerColor}`,
      position: 'sticky',
      top: 0,
      height: 60,
      margin: 0,
      padding: 0,
    },
    nav: {
      display: 'flex',
      width: '100%',
      maxWidth: 1040,
      margin: '0 auto',
      padding: 0,
      height: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexwrap: 'wrap',
      overflow: 'auto',
      scrollbarWidth: 'none',
    },
    navItem: {
      padding: 0,
      margin: 0,
      height: '100%',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    navItemLink: {
      height: '100%',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: 100,
    },
    navItemText: {
      fontFamily: 'Open Sans',
      fontSize: 12,
      fontWeight: 600,
      color: colors.textBody,
      textTransform: 'uppercase',
      textAlign: 'center',
    },
    navItemSelect: {
      borderBottom: `2px solid ${colors.colorBackgroundSecundary}`,
      cursor: 'pointer',
    },
    sectionContent: {
      borderBottom: `1px solid ${colors.dividerColor}`,
      wordBreak: 'break-word',
      paddingTop: '21px !important',
      paddingBottom: '10px !important',
      '&:last-child': {
        borderBottom: 'none',
      },
      [theme.breakpoints.down('md')]: {
        padding: 16,
      },
    },
    boxTextStep: {
      display: 'flex',
      alignItems: 'center',
      fontSize: theme.typography.pxToRem(16),
      color: colors.textBlackGray,
      fontWeight: 400,
      fontStyle: 'normal',
      fontFamily: 'Open Sans',
      paddingLeft: 16,
    },
  }));
  const classes = useStyles();

  // eslint-disable-next-line @typescript-eslint/ban-types

  const numberOrText = (data: string) => {
    if (!Number.isNaN(parseFloat(data))) {
      return parseFloat(
        data.replace(/\./g, '').replace(',', '.'),
      ).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    }
    return data;
  };
  const showTimeType = (
    tempo_total: number | undefined,
    tipo_tempo: string,
  ): string => {
    if (!tempo_total || tempo_total === 0) {
      return tipo_tempo;
    }

    switch (tipo_tempo) {
      case 'Anos':
        return tempo_total === 1 ? '1 Ano.' : `${tempo_total} Anos.`;
      case 'Meses':
        return tempo_total === 1 ? '1 Mês.' : `${tempo_total} Meses.`;
      case 'Semanas':
        return tempo_total === 1 ? '1 Semana.' : `${tempo_total} Semanas.`;
      case 'Dias úteis':
        return tempo_total === 1 ? '1 Dia útil.' : `${tempo_total} Dias úteis.`;
      case 'Dias corridos':
        return tempo_total === 1
          ? '1 Dia corrido.'
          : `${tempo_total} Dias corridos.`;
      case 'Horas':
        return tempo_total === 1 ? '1 Hora.' : `${tempo_total} Horas.`;
      case 'Minutos':
        return tempo_total === 1 ? '1 Minuto.' : `${tempo_total} Minutos.`;
      default:
        return `${tempo_total} ${tipo_tempo}.`;
    }
  };

  const handleNumberOrText = useCallback(numberOrText, []);

  return (
    <Box className={classes.main}>
      <nav className={classes.boxNav}>
        <List className={classes.nav}>
          <ListItem className={classes.navItem}>
            <LinkScroll
              smooth
              spy
              offset={-60}
              to='oquee'
              className={classes.navItemLink}
              activeClass={classes.navItemSelect}
            >
              <Typography className={classes.navItemText}>O QUE É?</Typography>
            </LinkScroll>
          </ListItem>
          <ListItem className={classes.navItem}>
            <LinkScroll
              className={classes.navItemLink}
              smooth
              spy
              offset={-60}
              to='exigencias'
              activeClass={classes.navItemSelect}
            >
              <Typography className={classes.navItemText}>
                Exigências
              </Typography>
            </LinkScroll>
          </ListItem>
          <ListItem className={classes.navItem}>
            <LinkScroll
              className={classes.navItemLink}
              smooth
              spy
              offset={-60}
              to='quempodeutilizar'
              activeClass={classes.navItemSelect}
            >
              <Typography className={classes.navItemText}>
                Quem pode utilizar?
              </Typography>
            </LinkScroll>
          </ListItem>
          <ListItem className={classes.navItem}>
            <LinkScroll
              className={classes.navItemLink}
              smooth
              spy
              offset={-60}
              to='prazo'
              activeClass={classes.navItemSelect}
            >
              <Typography className={classes.navItemText}>Prazo</Typography>
            </LinkScroll>
          </ListItem>
          <ListItem className={classes.navItem}>
            <LinkScroll
              className={classes.navItemLink}
              smooth
              spy
              offset={-60}
              to='custos'
              activeClass={classes.navItemSelect}
            >
              <Typography className={classes.navItemText}>Custos</Typography>
            </LinkScroll>
          </ListItem>
          <ListItem className={classes.navItem}>
            <LinkScroll
              className={classes.navItemLink}
              smooth
              spy
              offset={-60}
              to='etapas'
              activeClass={classes.navItemSelect}
            >
              <Typography className={classes.navItemText}>Etapas</Typography>
            </LinkScroll>
          </ListItem>
          <ListItem className={classes.navItem}>
            <LinkScroll
              className={classes.navItemLink}
              smooth
              spy
              offset={-60}
              to='outrasinformacoes'
              activeClass={classes.navItemSelect}
            >
              <Typography className={classes.navItemText}>
                Outras Informações
              </Typography>
            </LinkScroll>
          </ListItem>
        </List>
      </nav>

      <Box maxWidth='1040px' width='100%' margin='0 auto'>
        {/* <CategoriesMenu /> */}
        <Box>
          <section id='oquee' className={classes.sectionContent}>
            <Box>
              <Typography className={classes.title} variant='h4'>
                O que é este serviço?
              </Typography>
            </Box>
            <Box>
              <Typography
                className={classes.text}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(service.descricao) }}
              />
            </Box>
          </section>
          <section id='exigencias' className={classes.sectionContent}>
            <Box>
              <Typography className={classes.title} variant='h4'>
                Exigências para realizar o serviço
              </Typography>
            </Box>
            <Box>
              <Typography
                className={classes.text}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(service.requisitos) }}
              />
            </Box>
          </section>

          <section id='quempodeutilizar' className={classes.sectionContent}>
            <Typography className={classes.title} variant='h4'>
              Quem pode utilizar este serviço?
            </Typography>
            <Typography
              className={classes.text}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(service.publico) }}
            />
          </section>

          <section id='prazo' className={classes.sectionContent}>
            <Typography className={classes.title} variant='h4'>
              Prazos
            </Typography>
            <Typography className={classes.text}>
              {service.tipo_tempo && (
                <div>{showTimeType(service.tempo_total, service.tipo_tempo)}</div>
              )}
              {!!service.tempo && (
                <div>{`Atendimento presencial: ${service.tempo} min.`}</div>
              )}
              {!!service.tempo_online && (
                <div>{`Atendimento online: ${service.tempo_online} min.`}</div>
              )}
            </Typography>
          </section>
          <section id='custos' className={classes.sectionContent}>
            <Typography className={classes.title} variant='h4'>
              Quais os custos?
            </Typography>
            <Typography
              className={classes.text}
              style={{ paddingBottom: '16px' }}
            >
              {handleNumberOrText(service.custo)}
            </Typography>
          </section>
          <section id='etapas' className={classes.sectionContent}>
            <Typography className={classes.title}>Etapas</Typography>
            {service.jornada
              .sort((a, b) => a.ordem - b.ordem)
              .map(jornada => (
                <Box key={jornada.id}>
                  <Typography className={classes.subTitle} variant='h5'>
                    {jornada.ordem}-{jornada.titulo}
                  </Typography>
                  <Box className={classes.boxTextStep}>
                    <Typography
                      className={classes.text}
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(jornada.conteudo) }}
                    />
                  </Box>
                </Box>
              ))}
          </section>
          <section id='outrasinformacoes' className={classes.sectionContent}>
            <Typography className={classes.title} variant='h4'>
              Outras informações{' '}
            </Typography>
            <Typography
              className={classes.text}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(service.informacoes_extra) }}
            />
          </section>
        </Box>
      </Box>
    </Box>
  );
}
