/* eslint-disable linebreak-style */
const formatDateTime = (dateTime: string): string => {
  const date = new Date(dateTime);

  if (!Number.isNaN(date.getTime())) {
    // A data está no formato "yyyy-MM-dd" (exemplo: "2023-03-13")
    const day = date.getUTCDate();
    const month = date.toLocaleString('pt-BR', { month: 'long' });
    const year = date.getUTCFullYear();

    return `${day} de ${month} de ${year}`;
  }
  // A data está em outro formato (exemplo: "2023-05-11T23:05:58.567481+00:00")
  const utcDate = new Date(dateTime).toISOString();
  const formattedDate = new Date(utcDate).toLocaleString('pt-BR', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

  return formattedDate;
};

export default formatDateTime;
