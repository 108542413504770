import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';

interface ModalICPProps {
  open: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  onSelectGovBR: () => void;
  onSelectCertificadoDigital: () => void;
}

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    borderRadius: '12px',
    padding: theme.spacing(2),
    width: '710px',
  },
  title: {
    marginTop: '40px',
    fontSize: '1.5rem',
    fontWeight: 600,
    color: '#004F9F',
    textAlign: 'center',
  },
  access: {
    fontSize: '24px',
    color: '#595959',
    width: '100%',
    textAlign: 'center',
  },
  button: {
    borderRadius: '8px', 
    padding: theme.spacing(1, 2),
    fontSize: '1rem',
    fontWeight: 500,
    textTransform: 'none',
    height: '62px'
  },
  govBrButton: {
    color: '#004F9F',
    borderColor: '#004F9F',
    borderWidth: '2px',
    '& img': {
      marginRight: theme.spacing(1),
    },
  },
  certificadoButton: {
    color: '#004F9F',
    borderWidth: '2px',
    borderColor: '#004F9F',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const ModalICP = ({
  open,
  onClose,
  onSelectGovBR,
  onSelectCertificadoDigital,
}: ModalICPProps) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      classes={{ paper: classes.dialogPaper }}
      aria-labelledby="modal-icp-title"
    >
      <DialogTitle id="modal-icp-title" disableTypography>
        <Typography className={classes.title}>
          Selecione a forma de acesso ao sistema
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => onClose(false)}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Typography className={classes.access}>
        Acessar com
      </Typography>
      <DialogContent  className={classes.content}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={6}>
            <Button
              variant="outlined"
              fullWidth
              className={`${classes.button} ${classes.govBrButton}`}
              onClick={onSelectGovBR}
            >
              <img src="/assets/images/gov.br-logo.svg" alt="gov.br logo" height="24" />
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant="outlined"
              fullWidth
              className={`${classes.button} ${classes.certificadoButton}`}
              onClick={onSelectCertificadoDigital}
            >
              Certificado Digital <img src="/assets/images/certificate-badge-fill.svg" alt="gov.br logo"/>
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ModalICP;
