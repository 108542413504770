import { Box, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import DOMPurify from 'dompurify';
import React from 'react';
import { SearchI } from '../../../SearchService';

interface Props {
  service: SearchI;
}
const useStyles = makeStyles((theme: Theme) => ({
  main: {},
  title: {
    fontSize: theme.typography.pxToRem(16),
    color: '#000000',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    textAlign: 'left',
    marginTop: 10,
  },
  text: {
    fontSize: theme.typography.pxToRem(12),
    color: '#000000',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontFamily: 'Roboto',
  },
  title2: {
    fontSize: theme.typography.pxToRem(12),
    color: '#000000',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    textAlign: 'left',
    marginTop: 10,
  },
}));

export default function ServiceDetails({ service }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Box className={classes.main}>
      <Box>
        <Typography className={classes.title}>
          <span role='img' aria-labelledby='logo'>
            🔽
          </span>{' '}
          O que é o serviço?
        </Typography>

        <Typography
          className={classes.text}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(service.descricao) }}
        />

        <Typography className={classes.title}>
          <span role='img' aria-labelledby='logo'>
            🔽
          </span>{' '}
          Exigências
        </Typography>

        <Typography
          className={classes.text}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(service.requisitos) }}
        />

        <Typography className={classes.title}>
          <span role='img' aria-labelledby='logo'>
            🔽
          </span>{' '}
          Quem pode utilizar
        </Typography>

        <Typography
          className={classes.text}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(service.publico) }}
        />

        <Typography className={classes.title}>
          <span role='img' aria-labelledby='logo'>
            🔽
          </span>{' '}
          Quanto tempo leva
        </Typography>

        <Typography className={classes.text}>
          {`${service.tempo_total} ${service.tipo_tempo}`}
        </Typography>

        <Typography className={classes.title}>
          <span role='img' aria-labelledby='logo'>
            🔽
          </span>{' '}
          Passo a passo
        </Typography>

        {service.jornada
          .sort((a, b) => a.ordem - b.ordem)
          .map(jornada => (
            <>
              <Typography className={classes.title2}>
                {`${jornada.ordem}) ${jornada.titulo}`}
              </Typography>
              <Typography
                className={classes.text}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(jornada.conteudo) }}
              />
            </>
          ))}

        <Typography className={classes.title}>
          <span role='img' aria-labelledby='logo'>
            🔽
          </span>{' '}
          Outras informações{' '}
        </Typography>

        <Typography
          className={classes.text}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(service.informacoes_extra) }}
        />
        <Typography className={classes.title}>
          <span role='img' aria-labelledby='logo'>
            🔽
          </span>{' '}
          Formas de consumo do serviço{' '}
        </Typography>

        <ul className={classes.text}>
          {service.acesso_externo && (
            <li> Acesso link externo :{service.url_externo}</li>
          )}
          {service.online && <li> Agendamento online</li>}
          {service.agendavel && <li> Agendamento presencial</li>}
        </ul>
        <Typography className={classes.title} style={{ marginBottom: 20 }}>
          <span role='img' aria-labelledby='logo'>
            🔽
          </span>{' '}
          Unidades que atendem este serviço{' '}
        </Typography>
        {service.servicosUnidade.map(unidade => (
          <>
            <Typography className={classes.text}>
              {unidade.unidade.nome.toUpperCase()}
            </Typography>
            <Typography className={classes.text} style={{ marginBottom: 20 }}>
              {unidade.unidade.endereco} - {unidade.unidade.bairro} -{' '}
              {unidade.unidade.complemento && (
                <>{unidade.unidade.complemento}</>
              )}
            </Typography>
          </>
        ))}
      </Box>
    </Box>
  );
}
