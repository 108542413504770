/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/ban-types */
import DateFnsUtils from '@date-io/date-fns';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import ptBR from 'date-fns/locale/pt-BR';
import DOMPurify from 'dompurify';
import React, { useContext, useEffect, useState } from 'react';
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaWhatsapp,
  FaYoutube,
} from 'react-icons/fa';
import { IoTimeOutline } from 'react-icons/io5';
import {
  MdAssignmentInd,
  MdClose,
  MdExpandMore,
  MdLocationOn,
  MdWatchLater,
} from 'react-icons/md';
import { useParams } from 'react-router-dom';
import colorContext from '../../Context/colorContext';
import { getAllNews } from '../../services/noticias';
import { getOrgan, listAllOrgan } from '../../services/orgao';
import { searchServiceForOrgan } from '../../services/servico';
import { ES } from '../Home';
import { News } from '../News';
import { OrgansI } from '../OrgansList';
import { SearchI } from '../SearchService';
import { Breadcrumb, NewsNotice, ServiceList, Title } from './components';

interface Params {
  slug: string;
}
export interface SearchII extends ES {
  results: SearchI[];
}

export interface OrgansII extends ES {
  results: OrgansI[];
}

interface OrgansListProps {
  id: number;
  nome: string;
  sigla: string;
  slug: string;
}

export default function OrganDetail(): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      '& .MuiOutlinedInput-root': {
        color: theme.palette.primary.main,
        '&:hover fieldset': {
          borderColor: '#d3d3d3',
        },
      },
    },
    main: {
      width: '100%',
      display: 'flex',
      backgroundColor: colors.colorBackground,
      flexDirection: 'column',
      alignItems: 'center',
    },
    title: {
      fontWeight: 600,
      color: colors.textBody,
      fontSize: theme.typography.pxToRem(20),
      marginBottom: 5,
    },
    formControl: {
      marginTop: 20,
      marginBottom: 20,
      width: '100%',
    },
    titleName: {
      fontSize: 24,
      fontStyle: 'normal',
      fontWeight: 500,
      letterSpacing: '0em',
      textAlign: 'left',
      marginTop: 24,
      marginBottom: 16,
      color: colors.textBody,
    },
    text: {
      fontSize: 16,
      color: colors.textBody,
      fontWeight: 'normal',
      fontStyle: 'normal',
    },
    description: {
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: '0px',
      textAlign: 'justify',
      fontSize: theme.typography.pxToRem(16),
      color: colors.textBody,
    },
    iconContact: {
      cursor: 'pointer',
      marginRight: 10,
      color: 'rgba(15, 111, 183, 0.5)',
      fontSize: theme.typography.pxToRem(16),
    },
    card: {
      maxWidth: 360,
      width: '100%',
      minHeight: 48,
      borderRadius: 0,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      padding: '0px 10px 0px 20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginBottom: 2,
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: 'rgba(255, 255, 255, 0.25)',
      },
    },
    textCard: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(16),
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: '340px',
      textTransform: 'capitalize',
    },
    menuOption: {
      color: theme.palette.primary.main,
      textTransform: 'capitalize',
      whiteSpace: 'normal',
    },
    accordionDetail: {
      padding: '0px 42px 16px 16px',
      wordBreak: 'break-word',
      backgroundColor: colors.colorBackground,
    },
    accordionDetailLink: {
      padding: '16px ',
      wordBreak: 'break-word',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: colors.colorBackground,
    },
    accordion: {
      marginBottom: 26,
      width: '100%',
      boxShadow: colors.boxShadowColor,
      '&::before': {
        display: 'none',
      },
      borderRadius: 4,
    },
    accordionRoot: {
      minHeight: 62,
      backgroundColor: colors.colorBackground,
      fontWeight: 700,
    },
    boxGestor: {
      width: '100%',
    },
    img: {
      width: 100,
      height: 100,
      borderRadius: '50%',
    },
    contatoGestor: {
      fontWeight: 500,
      fontSize: 16,
      textAlign: 'justify',
      color: '#333333',
    },
    contatoGestorText: {
      fontWeight: 400,
    },
    links: {
      color: colors.accentColor,
      fontWeight: 400,
      fontSize: 16,
      marginLeft: -9,
    },
    buttonAgenda: {
      marginTop: 32,
      marginBottom: 40,
      backgroundColor: theme.palette.primary.main,
      height: 44,
      borderRadius: 4,
      padding: '10px 24px',
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    buttonAgendaText: {
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(14),
      textAlign: 'center',
      color: theme.palette.text.primary,
      textTransform: 'uppercase',
    },
    textHorario: {
      display: 'flex',
      justifyContent: 'center',
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 500,
      letterSpacing: 0,
      textAlign: 'left',
      color: colors.textBody,
    },
    btnSocial: {
      color: colors.accentColor,
      margin: '0 -4px',
    },
    textTitle: {
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 500,
      letterSpacing: 0,
      textAlign: 'left',
      color: colors.textBody,
      margin: '12px 0px',
    },
    textSubTitle: {
      fontWeight: 400,
      marginLeft: 3,
    },
    boxMap: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
    },
    boxService: {
      width: '100%',
    },
    barDivider: {
      width: '100%',
      marginTop: 8,
      height: 4,
      backgroundColor: colors.textBody,
      marginBottom: 30,
    },
    fullTitle: {
      fontWeight: 800,
      fontSize: theme.typography.pxToRem(32),
      color: colors.textBody,
      marginBottom: 16,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(24),
        textAlign: 'center',
      },
    },
    subTitleService: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(18),
      color: colors.subTitleColor,
      marginBottom: 40,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
        textAlign: 'center',
      },
    },
    titleModal: {
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
    },
    titleModalText: {
      color: theme.palette.text.primary,
      fontSize: 14,
      marginLeft: 5,
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    menuItem: {
      color: theme.palette.primary.main,
    },
    adornedEnd: {
      paddingRight: 0,
    },
    boxAgenda: {
      width: '100%',
      backgroundColor: theme.palette.primary.main,
      borderRadius: 4,
      minHeight: 96,
      margin: '8px 0px',
      height: 'auto',
      padding: 16,
    },
    boxInfoAgenda: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: 16,
      marginBottom: 10,
      maxWidth: 290,
      justifyContent: 'space-between',
    },
    textTitleLink: {
      margin: '8px 0',
      fontSize: '1em',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
    textSubTitleLink: {
      marginLeft: 8,
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    titleAgendaText: {
      fontSize: 18,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    subTitleAgendaText: {
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
      fontStyle: 'normal',
      fontWeight: 400,
    },
    buttonReturnModal: {
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 500,
      textAlign: 'left',
      color: '#333333',
      textTransform: 'uppercase',
      padding: 20,
    },
    Card: {
      [theme.breakpoints.down('sm')]: {
        padding: '64px 16px',
      },
    },
    divider: {
      height: 1,
      display: 'block',
      width: '100%',
      backgroundColor: colors.dividerColor,
    },
  }));
  const classes = useStyles();
  const matchesMobile = useMediaQuery('(min-width:1072px)');
  const params: Params = useParams();
  const [listSearch, setListSearch] = useState<SearchII>();
  const [organ, setOrgan] = useState<OrgansI>();
  const [expanded, setExpanded] = useState<string | false>(false);
  const [listOrgan, setListOrgan] = useState<OrgansListProps[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [newsList, setNewsList] = useState<News>();
  const [open, setOpen] = useState<boolean>(false);
  const [dateGestor, setDateGestor] = useState<Date>(new Date());

  const haveNews = atob(process.env.REACT_APP_HAVE_NOTICIAS);

  const getListOrgan = async (): Promise<void> => {
    setListOrgan(undefined);
    setLoading(true);
    try {
      const { data } = await listAllOrgan();
      setListOrgan(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getNews = async () => {
    const { data } = await getAllNews(1);
    setNewsList(data);
  };

  const getOrganEspecific = async (slug: string): Promise<void> => {
    setOrgan(undefined);
    setLoading(true);
    try {
      const { data } = await getOrgan(slug);
      setOrgan(data.results[0]);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const getSearch = async (page: number): Promise<void> => {
    setListSearch(undefined);
    setLoading(true);
    try {
      const { data } = await searchServiceForOrgan(params.slug, page);
      setListSearch(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getSearch(1);
    getOrganEspecific(params.slug);
    getListOrgan();
    getNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.slug]);

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const contactAccess = (type: string, url?: string): void => {
    if (organ) {
      switch (type) {
        case 'email':
          (window as any).location.href = `mailto:${organ.contatoOrgao.email}
            ?subject=Dúvida
            &body=Digite sua dúvida aqui`;
          break;

        default:
          (window as any).open(url);
          break;
      }
    }
  };

  const gestorActive = organ?.gestor.find(gestor => gestor.ativo);

  const handleClose = () => {
    setOpen(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  const formatTime = time => time.slice(0, 5);
  const isValidTime = time => time && time !== 'None';

  const DisplayTimes = (atendimento?: boolean) => {
    if (!organ || !organ.endereco_orgao) return null;
    let hora_inicio_manha;
    let hora_fim_manha;
    let hora_inicio_tarde;
    let hora_fim_tarde;

    if (atendimento) {
      hora_inicio_manha = organ.endereco_orgao.atendimento_hora_inicio_manha;
      hora_fim_manha = organ.endereco_orgao.atendimento_hora_fim_manha;
      hora_inicio_tarde = organ.endereco_orgao.atendimento_hora_inicio_tarde;
      hora_fim_tarde = organ.endereco_orgao.atendimento_hora_fim_tarde;
    } else {
      hora_inicio_manha = organ.endereco_orgao.hora_inicio_manha;
      hora_fim_manha = organ.endereco_orgao.hora_fim_manha;
      hora_inicio_tarde = organ.endereco_orgao.hora_inicio_tarde;
      hora_fim_tarde = organ.endereco_orgao.hora_fim_tarde;
    }

    let timeString = 'Não informado.';

    const { intervalo_ativo } = organ.endereco_orgao;

    if (intervalo_ativo === true) {
      if (
        isValidTime(hora_inicio_manha) &&
        isValidTime(hora_fim_manha) &&
        isValidTime(hora_inicio_tarde) &&
        isValidTime(hora_fim_tarde)
      ) {
        timeString = `${formatTime(hora_inicio_manha)} - ${formatTime(
          hora_fim_manha,
        )} / ${formatTime(hora_inicio_tarde)} - ${formatTime(hora_fim_tarde)}`;
      }
    } else if (isValidTime(hora_inicio_manha) && isValidTime(hora_fim_tarde)) {
      timeString = `${formatTime(hora_inicio_manha)} - ${formatTime(
        hora_fim_tarde,
      )}`;
    }

    return (
      <Typography className={classes.textHorario}>
        {timeString !== 'Não informado.' && (
          <IoTimeOutline
            style={{
              marginRight: 5,
              color: colors.textBody,
              width: 24,
              height: 24,
            }}
          />
        )}
        {timeString}
      </Typography>
    );
  };

  return (
    <Box className={classes.main}>
      {organ && listOrgan && (
        <>
          <Breadcrumb slug={organ.nome} />
          <Title organ={organ} listOrgan={listOrgan} />
        </>
      )}
      {loading && (
        <Box
          marginBottom='60px'
          marginTop='120px'
          width='100%'
          display='flex'
          justifyContent='center'
        >
          <CircularProgress />
        </Box>
      )}
      {organ && (
        <Box
          width='100%'
          flexDirection={matchesMobile ? 'row' : 'column'}
          display='flex'
          justifyContent='center'
          padding='64px 0px'
          borderBottom={`1px solid ${colors.dividerColor}`}
          className={classes.Card}
        >
          <Box
            display='flex'
            maxWidth='1040px'
            alignItems='center'
            width='100%'
            justifyContent='space-between'
            flexDirection='column'
          >
            <Accordion
              className={classes.accordion}
              expanded={expanded === 'panel1'}
              onChange={handleChange('panel1')}
            >
              <AccordionSummary
                classes={{ root: classes.accordionRoot }}
                expandIcon={
                  <MdExpandMore style={{ color: colors.accentColor }} />
                }
              >
                <Typography className={classes.title}>Quem somos</Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.accordionDetail }}>
                {organ.descricao ? (
                  <Typography
                    className={classes.text}
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(organ.descricao) }}
                  />
                ) : (
                  <Typography className={classes.text}>
                    Não informado.
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion
              className={classes.accordion}
              expanded={expanded === 'panel2'}
              onChange={handleChange('panel2')}
            >
              <AccordionSummary
                classes={{ root: classes.accordionRoot }}
                expandIcon={
                  <MdExpandMore style={{ color: colors.accentColor }} />
                }
              >
                <Typography className={classes.title}>Missão</Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.accordionDetail }}>
                {organ.missao ? (
                  <Typography
                    className={classes.text}
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(organ.missao) }}
                  />
                ) : (
                  <Typography className={classes.text}>
                    Não informado.
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion
              className={classes.accordion}
              expanded={expanded === 'panel3'}
              onChange={handleChange('panel3')}
            >
              <AccordionSummary
                classes={{ root: classes.accordionRoot }}
                expandIcon={
                  <MdExpandMore style={{ color: colors.accentColor }} />
                }
              >
                <Typography className={classes.title}>Visão</Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.accordionDetail }}>
                {organ.visao ? (
                  <Typography
                    className={classes.text}
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(organ.visao) }}
                  />
                ) : (
                  <Typography className={classes.text}>
                    Não informado.
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion
              className={classes.accordion}
              expanded={expanded === 'panel4'}
              onChange={handleChange('panel4')}
            >
              <AccordionSummary
                classes={{ root: classes.accordionRoot }}
                expandIcon={
                  <MdExpandMore style={{ color: colors.accentColor }} />
                }
              >
                <Typography className={classes.title}>Valores</Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.accordionDetail }}>
                {organ.valores ? (
                  <Typography
                    className={classes.text}
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(organ.valores) }}
                  />
                ) : (
                  <Typography className={classes.text}>
                    Não informado.
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion
              className={classes.accordion}
              style={{ display: organ.gestor.length > 0 ? 'block' : 'none' }}
              expanded={expanded === 'panel5'}
              onChange={handleChange('panel5')}
            >
              <AccordionSummary
                classes={{ root: classes.accordionRoot }}
                expandIcon={
                  <MdExpandMore style={{ color: colors.accentColor }} />
                }
              >
                <Typography className={classes.title}>
                  Gestor titular
                </Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.accordionDetail }}>
                {expanded && gestorActive?.arquivo ? (
                  <>
                    <Box className={classes.boxGestor}>
                      <img
                        src={gestorActive.arquivo}
                        alt='arquivo'
                        className={classes.img}
                        style={{
                          objectFit: 'cover',
                          height: 100,
                          width: 100,
                          overflow: 'hidden',
                        }}
                      />
                      <Box>
                        <Typography className={classes.titleName}>
                          {gestorActive.nome}
                        </Typography>
                        <Typography
                          className={classes.description}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(gestorActive.biografia),
                          }}
                        />
                      </Box>
                      <Box>
                        {/* <Typography className={classes.contatoGestor}>
                          Email:
                          {' '}
                          <span className={classes.contatoGestorText}>
                            <Button className={classes.links} onClick={(): void => contactAccess('email', organ.contatoOrgao.email)}>
                              {}
                            </Button>
                          </span>
                        </Typography>
                        <Typography className={classes.contatoGestor}>
                          Contato:
                          {' '}
                          <span className={classes.contatoGestorText}>
                            <Button className={classes.links} onClick={(): void => contactAccess('whats', organ.contatoOrgao.whatsapp)}>
                              (96)0000-0000
                            </Button>
                          </span>
                        </Typography> */}
                      </Box>
                      {/* <Button className={classes.buttonAgenda} onClick={handleClickOpen}>
                        <Typography className={classes.buttonAgendaText}>
                          Ver agenda
                        </Typography>
                      </Button> */}
                    </Box>
                  </>
                ) : (
                  <Typography className={classes.text}>
                    Não informado.
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion
              className={classes.accordion}
              expanded={expanded === 'panel6'}
              onChange={handleChange('panel6')}
            >
              <AccordionSummary
                classes={{ root: classes.accordionRoot }}
                expandIcon={<MdExpandMore style={{ color: colors.textBody }} />}
              >
                <Typography className={classes.title}>
                  Horário de funcionamento
                </Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.accordionDetail }}>
                {DisplayTimes()}
              </AccordionDetails>
            </Accordion>
            <Accordion
              className={classes.accordion}
              expanded={expanded === 'panel7'}
              onChange={handleChange('panel7')}
            >
              <AccordionSummary
                classes={{ root: classes.accordionRoot }}
                expandIcon={<MdExpandMore style={{ color: colors.textBody }} />}
              >
                <Typography className={classes.title}>
                  Horário de atendimento
                </Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.accordionDetail }}>
                {DisplayTimes(true)}
              </AccordionDetails>
            </Accordion>
            <Accordion
              className={classes.accordion}
              expanded={expanded === 'panel8'}
              onChange={handleChange('panel8')}
            >
              <AccordionSummary
                classes={{ root: classes.accordionRoot }}
                expandIcon={<MdExpandMore style={{ color: colors.textBody }} />}
              >
                <Typography className={classes.title}>Contato</Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.accordionDetail }}>
                <Box marginLeft='0px'>
                  <Typography className={classes.textTitle}>
                    Email:{' '}
                    <span className={classes.contatoGestorText}>
                      <Button
                        className={classes.links}
                        onClick={(): void =>
                          contactAccess('email', organ.contatoOrgao.email)
                        }
                      >
                        {organ.contatoOrgao.email
                          ? organ.contatoOrgao.email
                          : 'Não informado.'}
                      </Button>
                    </span>
                  </Typography>
                  <Typography className={classes.textTitle}>
                    Contato:{' '}
                    <span className={classes.contatoGestorText}>
                      <Button
                        className={classes.links}
                        onClick={(): void =>
                          contactAccess('contato', organ.contatoOrgao.telefone)
                        }
                      >
                        {organ.contatoOrgao.telefone
                          ? organ.contatoOrgao.telefone
                          : 'Não informado.'}
                      </Button>
                    </span>
                  </Typography>
                  <Typography className={classes.textTitle}>
                    Link para o site:{' '}
                    <span
                      className={classes.contatoGestorText}
                      style={{ textTransform: 'lowercase' }}
                    >
                      <Button
                        className={classes.links}
                        style={{ textTransform: 'lowercase' }}
                        onClick={(): void =>
                          contactAccess('site', organ.link.toLowerCase())
                        }
                      >
                        {organ.link
                          ? organ.link.toLowerCase()
                          : 'Não informado.'}
                      </Button>
                    </span>
                  </Typography>
                  <Box>
                    <Typography className={classes.textTitle}>
                      Redes sociais:
                    </Typography>
                    {organ.contatoOrgao.facebook && (
                      <IconButton
                        className={classes.btnSocial}
                        onClick={(): void =>
                          contactAccess('face', organ.contatoOrgao.facebook)
                        }
                      >
                        <FaFacebookF style={{ width: 16, height: 16 }} />
                      </IconButton>
                    )}
                    {organ.contatoOrgao.twitter && (
                      <IconButton
                        className={classes.btnSocial}
                        onClick={(): void =>
                          contactAccess('twit', organ.contatoOrgao.twitter)
                        }
                      >
                        <FaTwitter style={{ width: 16, height: 16 }} />
                      </IconButton>
                    )}
                    {organ.contatoOrgao.whatsapp && (
                      <IconButton
                        className={classes.btnSocial}
                        onClick={(): void =>
                          contactAccess('whats', organ.contatoOrgao.whatsapp)
                        }
                      >
                        <FaWhatsapp style={{ width: 16, height: 16 }} />
                      </IconButton>
                    )}
                    {organ.contatoOrgao.instagram && (
                      <IconButton
                        className={classes.btnSocial}
                        onClick={(): void =>
                          contactAccess('insta', organ.contatoOrgao.instagram)
                        }
                      >
                        <FaInstagram style={{ width: 16, height: 16 }} />
                      </IconButton>
                    )}
                    {organ.contatoOrgao.youtube && (
                      <IconButton
                        className={classes.btnSocial}
                        onClick={(): void =>
                          contactAccess('youtube', organ.contatoOrgao.youtube)
                        }
                      >
                        <FaYoutube style={{ width: 16, height: 16 }} />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className={classes.accordion}
              expanded={expanded === 'panel9'}
              onChange={handleChange('panel9')}
            >
              <AccordionSummary
                classes={{ root: classes.accordionRoot }}
                expandIcon={<MdExpandMore style={{ color: colors.textBody }} />}
              >
                <Typography className={classes.title}>Endereço</Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.accordionDetail }}>
                <Box className={classes.boxMap}>
                  {organ.endereco_orgao.source &&
                  organ.endereco_orgao?.source?.indexOf(
                    'https://www.google.com/maps/embed?',
                  ) !== -1 ? (
                    <iframe
                      width='100%'
                      title='mapa'
                      height='250px'
                      src={organ.endereco_orgao.source}
                      frameBorder='0'
                    />
                  ) : (
                    <Typography
                      style={{
                        color: colors.textBody,
                        // marginLeft: 20,
                        width: '100%',
                      }}
                    >
                      Não foi possível carregar o mapa.
                    </Typography>
                  )}
                  <Box>
                    {organ.endereco_orgao.endereco && (
                      <Typography className={classes.textTitle}>
                        Endereço:{' '}
                        <span className={classes.textSubTitle}>
                          <Button className={classes.links}>
                            {organ.endereco_orgao.endereco}
                          </Button>
                        </span>
                      </Typography>
                    )}
                    {organ.endereco_orgao.complemento && (
                      <Typography className={classes.textTitle}>
                        Complemento:{' '}
                        <span className={classes.textSubTitle}>
                          <Button className={classes.links}>
                            {organ.endereco_orgao.complemento}
                          </Button>
                        </span>
                      </Typography>
                    )}
                    {organ.endereco_orgao.bairro && (
                      <Typography className={classes.textTitle}>
                        Bairro:{' '}
                        <span className={classes.textSubTitle}>
                          <Button className={classes.links}>
                            {organ.endereco_orgao.bairro}
                          </Button>
                        </span>
                      </Typography>
                    )}
                    {organ.endereco_orgao.cep && (
                      <Typography className={classes.textTitle}>
                        CEP:{' '}
                        <span className={classes.textSubTitle}>
                          <Button className={classes.links}>
                            {organ.endereco_orgao.cep}
                          </Button>
                        </span>
                      </Typography>
                    )}
                    {organ.endereco_orgao.cidade &&
                      organ.endereco_orgao.cidade.estado && (
                        <Typography className={classes.textTitle}>
                          Cidade/ Estado:{' '}
                          <Button className={classes.links}>
                            <span
                              className={classes.textSubTitle}
                            >{`${organ.endereco_orgao?.cidade?.cidade} - ${organ.endereco_orgao?.cidade?.estado}`}</span>
                          </Button>
                        </Typography>
                      )}
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className={classes.accordion}
              expanded={expanded === 'panel10'}
              onChange={handleChange('panel10')}
            >
              <AccordionSummary
                classes={{ root: classes.accordionRoot }}
                expandIcon={<MdExpandMore style={{ color: colors.textBody }} />}
              >
                <Typography className={classes.title}>
                  Outros sites deste órgão
                </Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.accordionDetailLink }}>
                {organ.sites && organ.sites.length > 0 ? (
                  organ.sites.map(site => (
                    <Typography
                      key={site.titulo}
                      className={classes.textTitleLink}
                    >
                      {site.titulo}:
                      <a
                        href={site.link}
                        target='_blank'
                        rel='noopener noreferrer'
                        className={classes.textSubTitleLink}
                      >
                        {site.link}
                      </a>
                    </Typography>
                  ))
                ) : (
                  <Typography className={classes.textTitleLink}>
                    Nenhum site disponível.
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      )}

      <Dialog
        fullWidth
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <DialogTitle
          id='customized-dialog-title'
          className={classes.titleModal}
        >
          <Box display='flex' alignItems='flex-end'>
            <MdAssignmentInd />
            <Typography className={classes.titleModalText}>Agenda</Typography>
          </Box>
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={handleClose}
          >
            <MdClose />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div id='input-data'>
            <FormControl
              classes={{ root: classes.root }}
              variant='outlined'
              className={classes.formControl}
            >
              <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableFuture={false}
                  minDateMessage='Você não pode agendar em dias anteriores'
                  invalidDateMessage='A data informada é inválida'
                  minDate={new Date()}
                  InputProps={{
                    className: classes.menuItem,
                    classes: { adornedEnd: classes.adornedEnd },
                  }}
                  autoOk
                  variant='inline'
                  inputVariant='outlined'
                  label='Escolha o dia'
                  format='dd/MM/yyyy'
                  value={dateGestor}
                  InputAdornmentProps={{ position: 'end', color: '#000' }}
                  onChange={date => {
                    if (date) {
                      setDateGestor(date);
                    }
                  }}
                />
              </MuiPickersUtilsProvider>
              {/* {typeof (scheduleService) === 'string' && (
              <FormHelperText>{scheduleService}</FormHelperText>
              )} */}
            </FormControl>
          </div>
          <Box
            style={{
              height: 300,
              scrollBehavior: 'auto',
            }}
          >
            <Box className={classes.boxAgenda}>
              <Typography className={classes.titleAgendaText}>
                Reunião com o senhor presidente da república
              </Typography>
              <Box className={classes.boxInfoAgenda}>
                <Typography className={classes.subTitleAgendaText}>
                  <MdLocationOn style={{ fontSize: 15, marginRight: 6 }} />
                  Av.Dr. Helio Ribeiro, 1000
                </Typography>
                <Typography className={classes.subTitleAgendaText}>
                  <MdWatchLater style={{ fontSize: 15, marginRight: 6 }} />
                  10h40
                </Typography>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            className={classes.buttonReturnModal}
          >
            Voltar
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        display='flex'
        maxWidth='1040px'
        width='100%'
        justifyContent='space-between'
        flexDirection='column'
        padding='64px 0px'
      >
        {listSearch?.results.length ? (
          <>
            <Typography className={classes.fullTitle}>Serviços</Typography>
            <Typography className={classes.subTitleService}>
              Encontre o serviço desejado navegando pelas opções abaixo.
            </Typography>
            <ServiceList listSearch={listSearch} orgaoSlug={params.slug} />
            {/* <PaginationService getSearch={getSearch} listSearch={listSearch} /> */}
          </>
        ) : null}

        {listSearch?.results.length === 0 && !loading && (
          <Box
            minHeight='100px'
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <Typography
              style={{ textAlign: 'center', color: colors.textBody }}
              variant='h4'
            >
              Nenhum serviço encontrado
            </Typography>
          </Box>
        )}
      </Box>
      <span className={classes.divider} />
      {newsList &&
        newsList?.results &&
        haveNews === 'true' &&
        newsList?.results?.length && <NewsNotice newsList={newsList} />}
    </Box>
  );
}
