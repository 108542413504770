/**
 * Formata e mascara um CNPJ.
 * @param cnpj - O CNPJ a ser formatado e mascarado.
 * @returns O CNPJ formatado com partes mascaradas ou "CNPJ inválido" se o formato estiver incorreto.
 */
export const formatCNPJ = (cnpj: string): string => {
  const numeros = cnpj.replace(/\D/g, '');

  if (numeros.length !== 14) {
    return 'CNPJ inválido';
  }

  const cnpjMascarado = `${numeros.substring(0, 2)}.***.***/****-${numeros.substring(12, 14)}`;

  return cnpjMascarado;
};
