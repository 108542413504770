import { Fab, Theme, useScrollTrigger, Zoom } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { ReactElement, useContext, useEffect, useRef } from 'react';
import { IoArrowUp } from 'react-icons/io5';
import { useHistory, useLocation } from 'react-router-dom';
import colorContext from '../../../Context/colorContext';
import RefContext from '../../../Context/refContext';
import { sendCodeForSISECI } from '../../../services/auth';
import { setCookie } from '../../../utils/setCookie';
import Footer from '../../Footer';
import Header from '../../Header';
import Licence from '../../Licence';

interface Props {
  children: JSX.Element;
  // eslint-disable-next-line react/require-default-props
  window?: () => Window;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: theme.spacing(20),
      right: theme.spacing(1),
    },
  }),
);

function ScrollTop(props: Props) {
  const { children, window } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role='presentation' className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

export default function Dash(props: Props): ReactElement {
  const { children } = props;
  const history = useHistory();
  const location = useLocation();
  const { colors } = useContext(colorContext);

  useEffect(() => {
    const url = (window as any).location.search;
    const { pathname } = location;
    const query = new URLSearchParams(url);
    const code: any = query.get('code');
    if (code) {
      setCookie('pathname_redirect_gov', pathname);
      sendCodeForSISECI(code, history);
    }
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${atob(
      process.env.REACT_APP_RECAPTCHA,
    )}`;
    script.addEventListener('load', () => {});
    document.body.appendChild(script);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const refCategory = useRef(null);
  const refService = useRef(null);
  const refEstatistic = useRef(null);
  const refAttendance = useRef(null);
  const refNoticias = useRef(null);
  const refInicio = useRef(null);
  return (
    <RefContext.Provider
      value={{
        refCategory,
        refService,
        refEstatistic,
        refAttendance,
        refNoticias,
        refInicio,
      }}
    >
      <Header />
      {children}
      <footer>
        <Footer />
        <Licence />
        <ScrollTop {...props}>
          <Fab
            style={{
              right: 6,
              borderRadius: '50%',
              border: '1px solid #fff',
              backgroundColor: colors.accentColor,
            }}
            size='large'
            color='primary'
            aria-label='scroll back to top'
          >
            <IoArrowUp
              style={{ color: colors.colorBackground, width: 24, height: 24 }}
            />
          </Fab>
        </ScrollTop>
      </footer>
    </RefContext.Provider>
  );
}
