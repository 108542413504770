import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';

import DOMPurify from 'dompurify';
import colorContext from '../../Context/colorContext';
import { getNotices } from '../../services/avisos';
import getCookie from '../../utils/getCookies';
import { setCookie } from '../../utils/setCookie';

export interface INotice {
  titulo: string;
  conteudo: string;
  id: number;
}

export default function ModalAlertsDialog(): JSX.Element {
  const { colors } = useContext(colorContext);
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      '&.MuiDialogContent-root': {
        backgroundColor: colors.colorBackgroundSecundary,
      },
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: colors.textBody,
    },
    titleDialog: {
      color: colors.textBody,
      backgroundColor: colors.colorBackground,
      paddingTop: 20,
      paddingBottom: 20,
      paddingRight: 40,
    },
    contentDialog: {
      color: colors.textBody,
      backgroundColor: colors.colorBackground,
      width: '100%',
    },
  }));
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [notice, setNotice] = useState<INotice | undefined>();

  const loadCurrentNotices = async () => {
    try {
      const { data } = await getNotices();
      if (data) {
        setNotice(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    loadCurrentNotices();
  }, []);

  useEffect(() => {
    const noticeViewed = getCookie('notice-visualized');
    if (notice && noticeViewed !== notice.id.toString()) {
      setCookie('notice-visualized', notice.id.toString());
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [notice]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      fullWidth
      maxWidth='md'
    >
      <DialogTitle className={classes.titleDialog}>
        {notice?.titulo}
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={handleClose}
        >
          <MdClose />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers classes={{ root: classes.root }}>
        <Box
          className={classes.contentDialog}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(notice?.conteudo || '') }}
        />
      </DialogContent>
    </Dialog>
  );
}
