// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { createTheme, Theme } from '@material-ui/core';

interface ThemeProps {
  accentColor: string;
  accentColorBackground: string;
  colorBackground: string;
  colorBackgroundSecundary: string;
  textBody: string;
  textAccentColor: string;
  badgeColor: string;
  cardAccentColor: string;
  accordionTextColor: string;
  serviceCardBackgroundColor: string;
  favoriteBoxColor: string;
  breadcrumbButtonColorHover: string;
  borderShadowsColor: string;
}

const theme = (color: ThemeProps, type: string): Theme => {
  const materialTheme = createTheme({
    palette: {
      type: type === 'normal' ? 'light' : 'dark',
      primary: {
        main: color.accentColor,
        dark: atob(process.env.REACT_APP_COLOR_PRIMARY),
      },
    },
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: color.accentColorBackground,
        },
        toolbarBtn: {
          color: color.accentColor,
        },
      },
      MuiPickersDay: {
        day: {
          color: color.textBody,
        },
        daySelected: {
          backgroundColor: color.accentColor,
          color: color.colorBackground,
        },
        dayDisabled: {
          color: color.breadcrumbButtonColorHover,
        },
        current: {
          color: color.textBody,
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: color.textBody,
        },
      },
    },
  });
  return materialTheme;
};

export { theme as default };
