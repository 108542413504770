/* eslint-disable @typescript-eslint/no-unsafe-return */
// eslint-disable-next-line import/no-cycle
import { News } from '../../Pages/News';
import adapterNewsLiferay from './noticias_liferay';
import adapterNewsWPV2 from './noticias_wp_v2';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const adapterNews = (newsData: any, type: 'liferay' | 'wp_v2') => {
  if (atob(process.env.REACT_APP_NOTICIAS_EXTERNAL) === 'true') {
    const newsAdapter = {} as News;

    if (type === 'liferay') {
      return adapterNewsLiferay(newsData);
    }

    if (type === 'wp_v2') {
      return adapterNewsWPV2(newsData);
    }
    return newsAdapter;
  }

  return newsData as News;
};

export default adapterNews;
